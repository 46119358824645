import { useForceUpdate, useInteraction } from '@core-ui/hooks';
import Loader from '@core-ui/loader';
import { Nullable } from '@core-ui/types';
import Grid from '@mui/material/Grid';
import State from '@/app/types/state';
import { useAppSelector } from '@/hooks/useAppSelector';
import Skeleton from '@mui/material/Skeleton';
import { SxProps, Theme } from '@mui/material/styles';
import isEqual from 'lodash/isEqual';
import React, { FC, useCallback, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { ActionFunctionAny } from 'redux-actions';
import { Selector } from 'reselect';

const OFFSET = 32;

const skeletonRowStyle: SxProps<Theme> = {
  margin: '10px 0',
};

interface IProps {
  getChunk: ActionFunctionAny<any>;
  selector: Selector<State, { isAllDataReceived: boolean; isChunkLoading: boolean }>;
}

const ChunkLoader = (props: IProps) => {
  const { getChunk, selector } = props;

  const forceUpdate = useForceUpdate();
  const ref = useRef<Nullable<HTMLDivElement>>(null);
  const dispatch = useDispatch();

  const { isAllDataReceived, isChunkLoading } = useAppSelector(selector, isEqual);

  const getMoreData = useCallback(() => {
    if (!isAllDataReceived && !isChunkLoading) {
      dispatch(getChunk(null));
    }
  }, [isAllDataReceived, isChunkLoading]);

  useInteraction({
    getMoreData,
    ref,
    allDataReceived: isAllDataReceived,
    offset: OFFSET,
  });

  useEffect(() => {
    if (!isChunkLoading && !isAllDataReceived) {
      forceUpdate();
    }
  }, [isChunkLoading, isAllDataReceived]);

  return (
    <Grid ref={ref} container minHeight="1px" justifyContent="center" alignItems="center">
      {isChunkLoading && (
        <>
          <Skeleton sx={skeletonRowStyle} variant="rounded" width="100%" height={20} />
          <Skeleton sx={skeletonRowStyle} variant="rounded" width="100%" height={20} />
          <Skeleton sx={skeletonRowStyle} variant="rounded" width="100%" height={20} />
          <Skeleton sx={skeletonRowStyle} variant="rounded" width="100%" height={20} />
          <Skeleton sx={skeletonRowStyle} variant="rounded" width="100%" height={20} />
          <Skeleton sx={skeletonRowStyle} variant="rounded" width="100%" height={20} />
          <Skeleton sx={skeletonRowStyle} variant="rounded" width="100%" height={20} />
          <Skeleton sx={skeletonRowStyle} variant="rounded" width="100%" height={20} />
          <Skeleton sx={skeletonRowStyle} variant="rounded" width="100%" height={20} />
          <Skeleton sx={skeletonRowStyle} variant="rounded" width="100%" height={20} />
        </>
      )}
    </Grid>
  );
};

export default ChunkLoader;
