import { exhaustiveGuard } from '@/app/utils/app';
import StatusInfo, { StatusInfoColors } from '@/components/StatusInfo';
import { ApproveStatusEnum, FileStatusEnum } from '@/generated';
import { FC, ReactNode } from 'react';
import {
  CALCULATED_STATUS,
  INVOICE_CALCULATED_STATUS_ENUM,
  QUOTE_STATUS_ENUM,
} from '../../pages/backoffice/Requests/types';

type StatusValues =
  | CALCULATED_STATUS
  | INVOICE_CALCULATED_STATUS_ENUM
  | QUOTE_STATUS_ENUM
  | ApproveStatusEnum
  | FileStatusEnum;

// TODO: это какой-то ад, нужно разобраться со всеми этими enum (по возможности заменив их на сгенеренные из свагера)
//  и соответствующими им цветами
function getColor(status: StatusValues) {
  switch (status) {
    case CALCULATED_STATUS.REQUEST_AWAITING_APPROVAL:
    case CALCULATED_STATUS.QUOTE_AWAITING_APPROVAL:
    case CALCULATED_STATUS.INVOICE_AWAITING_APPROVAL:
    case CALCULATED_STATUS.AWAITING_INVOICE:
    case CALCULATED_STATUS.REQUEST_DISPUTE:
    case INVOICE_CALCULATED_STATUS_ENUM.AWAITING:
    case ApproveStatusEnum.AWAITING:
    case QUOTE_STATUS_ENUM.AWAITING:
      return StatusInfoColors.PENDING;
    case CALCULATED_STATUS.REQUEST_APPROVED:
    case CALCULATED_STATUS.QUOTE_APPROVED:
    case INVOICE_CALCULATED_STATUS_ENUM.APPROVED:
    case ApproveStatusEnum.APPROVED:
    case QUOTE_STATUS_ENUM.APPROVED:
      return StatusInfoColors.SUCCESS;
    case CALCULATED_STATUS.PAID:
    case INVOICE_CALCULATED_STATUS_ENUM.PAID:
      return StatusInfoColors.DELIVERED;
    case CALCULATED_STATUS.DONE:
    case CALCULATED_STATUS.PAID_DELIVERED:
    case CALCULATED_STATUS.DELIVERED:
    case FileStatusEnum.DIGITIZED:
      return StatusInfoColors.DONE;
    case CALCULATED_STATUS.REQUEST_DECLINED:
    case CALCULATED_STATUS.QUOTE_DECLINED:
    case CALCULATED_STATUS.INVOICE_DECLINED:
    case CALCULATED_STATUS.QUOTE_ERROR:
    case CALCULATED_STATUS.INVOICE_ERROR:
    case CALCULATED_STATUS.NONE:
    case INVOICE_CALCULATED_STATUS_ENUM.DECLINED:
    case ApproveStatusEnum.DECLINED:
    case QUOTE_STATUS_ENUM.DECLINED:
    case FileStatusEnum.RETURNED:
    case FileStatusEnum.UNKNOWN:
      return StatusInfoColors.DANGER;
    case CALCULATED_STATUS.COLLECTING_QUOTES:
    case CALCULATED_STATUS.PAYMENT_PENDING_APPROVAL:
    case CALCULATED_STATUS.AWAITING_PAYMENT:
    case FileStatusEnum.BEING_DIGITIZED:
    case CALCULATED_STATUS.INVOICE_BEING_DIGITIZED:
    case CALCULATED_STATUS.QUOTE_BEING_DIGITIZED:
      return StatusInfoColors.APPROVAL;
    default:
      return exhaustiveGuard(status);
  }
}

interface IProps {
  status: StatusValues;
  label?: string | ReactNode;
}

const StatusBadge: FC<IProps> = (props) => {
  const { status = FileStatusEnum.BEING_DIGITIZED, label } = props;

  return <StatusInfo color={getColor(status)}>{label || status.replace(/_/g, ' ')}</StatusInfo>;
};

export default StatusBadge;
