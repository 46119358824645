import { COLOURS, components, HEIGHT_SIZE, theme as coreTheme } from '@core-ui/styles';
import createTheme from '@mui/material/styles/createTheme';
import { TRANSITION_TIMING_FUNCTION } from '@/app/consts/app';
import MuiCssBaseline from './baseline';
import { GREY_PALETTE } from './consts';

const palette = {
  text: {
    primary: COLOURS.TEXT_CONTROL.MAIN,
    secondary: COLOURS.HUE_COCONUT.HUE_128,
    white: COLOURS.WHITE,
    error: COLOURS.HUE_STRAWBERRY.HUE_100,
  },
  primary: {
    main: GREY_PALETTE[160],
  },
  secondary: {
    main: GREY_PALETTE[60],
  },
  primaryBlue: {
    main: COLOURS.HUE_FIG.HUE_100,
  },
};

const theme = createTheme({
  palette,
  typography: {
    ...coreTheme.typography,
  },
  spacing: coreTheme.spacing,
  breakpoints: coreTheme.breakpoints,
});

theme.components = {
  ...components,

  MuiTable: {},
  MuiTableRow: {},
  MuiTableCell: {
    styleOverrides: {
      root: {
        padding: '8px 12px',
        verticalAlign: 'top',
      },
      head: {
        ...theme.typography.body2,
        fontWeight: 600,
        backgroundColor: COLOURS.HUE_COCONUT.HUE_153,
        color: COLOURS.HUE_COCONUT.HUE_16,
        height: HEIGHT_SIZE.tableHeaderHeight,
        verticalAlign: 'middle',
        border: 0,
        borderLeft: `1px solid ${COLOURS.HUE_COCONUT.HUE_16}`,
        borderRight: `1px solid ${COLOURS.HUE_COCONUT.HUE_16}`,
        borderRadius: 0,

        '&:first-of-type': {
          borderLeft: 0,
          borderRadius: '4px 0 0 4px',
        },

        '&:last-of-type': {
          borderRight: 0,
          borderRadius: '0 4px 4px 0',
        },
      },
      body: {
        ...theme.typography.body1,
        color: COLOURS.HUE_COCONUT.HUE_195,
        height: 48,
        borderRight: `1px solid ${COLOURS.HUE_COCONUT.HUE_50}`,

        '&:last-of-type': {
          borderRight: 0,
        },
      },
    },
  },

  MuiPaper: {
    styleOverrides: {
      root: {
        // boxShadow: 'none',
      },
    },
  },

  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        backgroundColor: COLOURS.HUE_COCONUT.HUE_172,
      },
      tooltipArrow: {
        backgroundColor: COLOURS.HUE_COCONUT.HUE_172,
        color: COLOURS.WHITE,
      },
      arrow: {
        color: COLOURS.HUE_COCONUT.HUE_172,
      },
    },
  },

  MuiDialog: {
    styleOverrides: {
      paper: {
        height: '100%',
      },
    },
  },

  MuiDialogContent: components.MuiDialogContent,

  MuiDialogActions: {
    styleOverrides: {
      root: {
        padding: 0,
        marginTop: '16px',
      },
    },
  },

  MuiButtonGroup: {
    styleOverrides: {
      root: {
        boxShadow: 'none',
      },
    },
  },

  MuiIconButton: {
    styleOverrides: {
      root: {
        '&:hover': {
          backgroundColor: 'initial',
        },
      },
    },
  },

  MuiAvatar: {
    styleOverrides: {
      colorDefault: {
        backgroundColor: COLOURS.HUE_COCONUT.HUE_100,
      },

      rounded: {
        borderRadius: 4,
      },
    },
  },

  MuiCssBaseline,

  MuiButtonBase: {
    defaultProps: {
      disableRipple: true,
    },
  },

  MuiSvgIcon: {
    styleOverrides: {
      root: {
        verticalAlign: 'middle', // for table
      },

      fontSizeLarge: {
        fontSize: 24,
      },

      fontSizeMedium: {
        fontSize: 20,
      },

      fontSizeSmall: {
        fontSize: 16,
      },
    },
  },

  MuiContainer: {
    styleOverrides: {
      root: {
        display: 'flex',
        flexDirection: 'column' as const,
        padding: '0 20px',
      },
    },
  },

  MuiButton: {
    defaultProps: {
      disableRipple: true,
      disableTouchRipple: true,
    },
  },

  MuiMenuItem: {
    defaultProps: {
      disableRipple: true,
      disableTouchRipple: true,
    },
  },

  MuiToggleButton: {
    styleOverrides: {
      root: {
        textTransform: 'none' as const,
        color: COLOURS.HUE_FIG.HUE_100,
      },
    },
  },

  MuiTabs: {
    styleOverrides: {
      root: {
        '.MuiButtonBase-root': {
          transition: `all 250ms ${TRANSITION_TIMING_FUNCTION}`,
        },
      },
    },
  },
};

export default theme;
