import Button, { ButtonProps } from '@core-ui/button';
import { COLOURS } from '@core-ui/styles';
import { Nullable } from '@core-ui/types';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { TRANSITION_TIMING_FUNCTION } from '@/app/consts/app';
import { useIntl } from 'components/Intl';
import Menu from 'components/Menu';
import { IMenuOption } from '@/components/Menu/Menu';
import { DotsThreeVertical } from '@phosphor-icons/react';
import React, { useRef, useState } from 'react';

interface IProps {
  options: IMenuOption[];
  loading?: boolean;
  disabled?: boolean;
  disablePortal?: boolean;
  // button border radius variant, circle by default
  variant?: 'circle' | 'square';
  padding?: number;
  styles?: SxProps<Theme>;
  buttonProps?: ButtonProps;
  zIndex?: number;
  dataTestId?: string;
}

const MoreButton: React.FC<IProps> = (props) => {
  const {
    options,
    loading,
    disabled,
    buttonProps,
    disablePortal,
    variant = 'circle',
    padding,
    styles = {},
    zIndex,
    dataTestId,
  } = props;

  const [open, setOpen] = useState(false);
  const anchorRef = useRef<Nullable<HTMLDivElement>>(null);
  const intl = useIntl();

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: TouchEvent | MouseEvent | React.MouseEvent<HTMLLIElement>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as Node)) {
      return;
    }

    setOpen(false);
  };

  const defaultStyles: SxProps<Theme> = {
    minWidth: 'auto',
    padding: padding ? `${padding}px !important` : '7px !important',
    background: `${COLOURS.WHITE} !important`,
    borderRadius: variant === 'circle' ? '16px !important' : '4px !important',
    borderColor: `${COLOURS.HUE_COCONUT.HUE_32} !important`,
    color: `${COLOURS.HUE_COCONUT.HUE_172} !important`,
    transition: `all 250ms ${TRANSITION_TIMING_FUNCTION}`,

    '&:hover': {
      background: `${COLOURS.WHITE} !important`,
      borderColor: `${COLOURS.HUE_COCONUT.HUE_100} !important`,
      color: `${COLOURS.HUE_COCONUT.HUE_195} !important`,
    },
  };

  return (
    <>
      <div ref={anchorRef} style={{ display: 'inline-block', height: '100%' }}>
        <Button
          variant="outlined"
          onClick={handleToggle}
          sx={{
            ...defaultStyles,
            ...styles,
          }}
          loading={loading}
          disabled={disabled}
          icon={<DotsThreeVertical weight="bold" />}
          aria-label={intl.formatMessage({ id: 'aria.label.open_more_options_menu' })}
          {...buttonProps}
        />
      </div>

      <Menu
        options={options}
        open={open}
        anchorRef={anchorRef}
        onClose={handleClose}
        disablePortal={disablePortal}
        zIndex={zIndex}
      />
    </>
  );
};

export default MoreButton;
