import { COLOURS } from '@core-ui/styles';
import { IOpenLayerFeature } from '@/pages/backoffice/Requests/types';
import { Zoom } from 'ol/control';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import TileLayer from 'ol/layer/Tile';
import { get } from 'ol/proj';
import XYZ from 'ol/source/XYZ';
import { Circle as CircleStyle, Fill, Stroke, Style } from 'ol/style';
import TileGrid from 'ol/tilegrid/TileGrid';
import View from 'ol/View';

export const mapExtent = [0.0, -7200.0, 5400.0, 0.0];
export const mapMinZoom = 0;
export const mapMaxZoom = 3;
export const mapMaxResolution = 1.0;
export const tileExtent = [0.0, -7200.0, 5400.0, 0.0];
export const tileWidth = 1024;
export const tileHeight = 1024;

const mapResolutions = [];
for (let z = 0; z <= mapMaxZoom; z++) {
  // eslint-disable-next-line no-restricted-properties
  mapResolutions.push(Math.pow(2, mapMaxZoom - z) * mapMaxResolution);
}

const mapTileGrid = new TileGrid({
  tileSize: [tileWidth, tileHeight],
  extent: tileExtent,
  // minZoom: mapMinZoom,
  resolutions: mapResolutions,
});

export const layers = (mapId?: string) =>
  new TileLayer({
    source: new XYZ({
      projection: 'PIXELS',
      tileGrid: mapTileGrid,
      tilePixelRatio: 1.0,
      url: `https://cdn.myocean.tech/boats/${mapId}/{z}/{x}/{y}.png`,
      minZoom: mapMinZoom,
      maxZoom: mapMaxZoom,
    }),
  });

export const featureFabric = (item: IOpenLayerFeature) =>
  new Feature({ geometry: new Point(item.coordinates), ...item });

export const zoom = () =>
  new Zoom({
    className: 'ol-zoom-readonly',
    zoomInClassName: 'ol-zoom-in-readonly',
    zoomOutClassName: 'ol-zoom-out-readonly',
    zoomInLabel: '',
    zoomOutLabel: '',
  });

export const view = () =>
  new View({
    // @ts-ignore
    projection: get('PIXELS'),
    extent: mapExtent,
    maxResolution: mapTileGrid.getResolution(mapMinZoom),
    center: [0, 0],
    zoom: mapMaxZoom,
  });

export const pointStyle = () =>
  new Style({
    image: new CircleStyle({
      radius: 10,
      fill: new Fill({
        color: COLOURS.HUE_STRAWBERRY.HUE_100,
      }),
      stroke: new Stroke({
        width: 2,
        color: COLOURS.WHITE,
      }),
    }),
  });
